.node-type-event {
  ul.tabs.primary {
    background: $white;
    padding: 0;
    padding: 10px;
    margin: 0;
    border: navajowhite;
    text-align: center;
    border-bottom: 1px solid $primary-blue;
    padding-bottom: 0;
  }
}

#content.content--gray {
  background-color: transparent;
}

.section--about {
  background-color: $white;
}

.about--container {
  padding: 20px;
}

.container_12 {
  box-sizing: border-box;
}

@media screen and (min-width: 768px) {
  .about--container {
    display: flex;
  }

  .left {
    flex-basis: 60%;
    margin-right: 4%;
  }

  .right {
    flex-basis: 40%;
  }
}

@media screen and (min-width: 981px) {
  .left {
    flex-basis: 70%;
  }

  .right {
    flex-basis: 30%;
  }
}

.info-panel-after {
  text-align: center;
}

.info-panel {
  background-color: $light-gray;
  padding: 20px;
  margin-top: 20px;

  &__item__icon {
    width: 16px;
    margin-right: 15px;
  }

  &__item {
    margin-bottom: 20px;
  }

  .button {
    background: $primary-blue;
    color: $white;
    padding: 10px;
    display: block;
    text-align: center;
    margin-top: 25px;
  }

  a {
    color: #0096d4;
  }
}

.schedule-container {
  span.label {
    margin-right: 10px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
}

.section__header {
  text-align: center;
  margin-bottom: 60px;
}

.schedule {
  background-color: $white;

  .column {
    border-bottom: 1px solid $light-gray;
    padding: 10px 20px;
  }
}

.section--schedule {
  background-color: $light-gray;
}

.dashicons--blue {
  color: $primary-blue;
  margin-right: 5px;
}

.dashicons--rotate {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-right: 10px;
}
