@import 'variables';
@import 'events';

.frontpage-article-lift {
    margin: 0 30px;
    min-height: 167px;
    background-size: cover;
    position: relative;
    padding: 18px;
    padding-right: 18px * 3;
    display: flex;
    flex-direction: column;
    justify-content: center;


    &:before {
        z-index: 1;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        background-color: hsla(197, 100%, 36%, 0.64);
    }
}

.frontpage-article-lift {

    .views-field-title {
        position: relative;
        z-index: 2;
        a {
            color: white;
            font-size: 20px;
            line-height: 1.3;

            @media screen and (min-width: 768px) {
                font-size: 30px;
            }
        }
    }

    .views-field-view-node {
        font-size: 18px;
        margin-top: 12px;
        position: relative;
        z-index: 2;
        a {
            color: white;
        }
    }

}

/*
* Additional flags.
*/

a.langSE {
    display: inline-block;
    padding: 0.15em 0 0.5em 2.5em;
    margin: 0 0 1em 1em;
    background: url("../images/lang-flag-se.png") 2px 2px no-repeat;
    background-size: 20px;
}

a.langPL {
    display: inline-block;
    padding: 0.15em 0 0.5em 2.5em;
    margin: 0 0 1em 1em;
    background: url("../images/lang-flag-pl.png") 2px 2px no-repeat;
    background-size: 20px;
}


// GDPR.
.popup-content.info {
    padding: 30px;
    div#popup-text h2 span {
        font-size: 12px;
    }
}



